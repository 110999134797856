.products-container {
  display: flex;
  flex-wrap: wrap;

  /* border: solid 2px blue; */
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10px;
}

.product-card {
  height: 400px;
  width: 300px;
  margin: 10px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 1px 3px rgb(228, 228, 228);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: scale(1.05);
}

.image-placeholder {
  border-radius: 8px;
  height: 250px;
  width: 270px;
}

.bold {
  font-weight: bold;
}

.product-name {
  padding-left: 3px;
  font-family: Arial, Helvetica, sans-serif;
}
.product-reviews {
  padding-left: 3px;
  font-family: Arial, Helvetica, sans-serif;
}

.product-description {
  padding-top: 0;
  margin-top: 3px;
  padding-left: 3px;
  font-family: Arial, Helvetica, sans-serif;
  overflow: auto;
}

.price {
  padding-left: 3px;
  margin-top: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  border-bottom: 1px solid rgb(226, 226, 226);
  margin-bottom: 0;
  padding-bottom: 0;
}

.back-button {
  color: rgb(104, 104, 104);
  border: none;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-top: 10px;
  padding-left: 15px;
  font-size: 15px;
  padding-bottom: 6px;
}

.back-button:hover {
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}
