.about-container {
  height: 400px;
  background-color: bisque;
  display: flex;
  flex-direction: column;
}

.what-is {
  display: flex;
  align-self: center;
  padding-top: 30px;
  font-size: 30px;
  padding-bottom: 40px;
  text-decoration: underline;
}

.about-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 300px;
}

.about-desc {
  width: 300px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  padding: 0px 10px;
}

.lg {
  width: 350px;
}

.lg2 {
  margin-top: 35px;
}
.about-header {
  padding-top: 20px;
  font-weight: 100;
  font-size: 20px;
}

.about-header-text {
}

.links-container {
  background-color: rgb(67, 96, 210);
  color: white;
  height: 140px;
}

.link-header {
  font-weight: bold;
  margin-left: 30px;
  padding-bottom: 20px;
  padding-top: 5px;
}

.links {
  margin-left: 30px;
}
