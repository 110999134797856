.SingleReview {
  display: flex;
}

.MainBlock {
  display: flex;
  border-top: 1px solid rgb(200, 200, 200);
  flex-direction: column;
  padding-left: 10px;
}

.pagination {
  display: flex;
  flex-direction: row;
}
.ReviewBlock {
  display: flex;
  width: 40%;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(211, 211, 211);
}

.total-reviews {
  font-size: 30px;
}

button:hover {
  cursor: pointer;
}

.ReviewPageButton {
  display: flex;
  min-width: 5vw;
  background-color: white;
  border: none;
  color: rgb(255, 119, 0);
}

.spaced {
  margin-left: 15px;
}
.per-page {
  color: rgb(255, 119, 0);
  font-size: 14px;
}

.show {
  color: rgb(255, 119, 0);
  font-size: 14px;
}

.show:hover,
.per-page:hover,
.ReviewPageButton:hover,
.select-page:hover {
  cursor: pointer;
  color: rgb(222, 103, 0);
}

.username_on_Review {
  margin-left: 5px;
  padding: 0;
  margin-top: 10px;
}

.RatingsBlock {
  margin-left: 5px;
}

.modal-Review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
}

.ReviewForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-review {
  width: 300px;
  max-width: 300px;
  height: 150px;
  max-height: 150px;
  resize: none;
}

.review-header {
  font-weight: 100;
}
/* .username_on_Review{

} */
/* .RatingsBlock{
display:flex;
flex-direction: column;
}
.ReviewContent{

} */
