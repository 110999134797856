.modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-left: 5rem;
    padding-right: 5rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10000;
}


  


.modal button {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.delete-button-model {
    background-color: rgb(255, 119, 0);
    color: white;
    width: 10rem;
    height: 2rem;
    margin-top: 2rem;
    margin-left: -1rem;
    -webkit-box-shadow: 1px 0px 0px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 0px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 0px 2px rgba(0, 0, 0, 0.75);
    cursor: pointer;
  }