.modal-test {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  width: 500px;
  border-radius: 12px;
  color: rgb(0, 0, 0);
  background-color: white;
  border: solid black 3px;
}

.modal-test > h1 {
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.signup-form > input {
  width: 80%;
  border-radius: 5px;
  height: 40px;
  background-color: rgb(255, 255, 255);
}

.signup-form > label {
  position: relative;
  margin: 10px 0;
}

.signup-button {
  border-radius: 10px;
  margin-top: 30px;
  width: 82%;
  height: 30px;
  background-color: rgb(255, 119, 0);
  color: white;
}
.signup-button:hover {
  cursor: pointer;
  box-shadow: 2px 2px black;
}

.signup-button:disabled {
  background-color: grey;
  cursor: auto;
  box-shadow: none;
}

.errorz {
  color: red;
  list-style: none;
}
