.cart-modal-container {
  display: flex;
  height: 350px;
  width: 350px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cart-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 350px;
  width: 300px;
}

.cart-img-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 300px;
  flex-direction: column;
}

.cart-text-container {
  padding: 5px;
}

.cart-buttons-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
}

.cart-image {
  display: flex;
  object-fit: fill;
  width: 90%;
  height: 90%;
}

.cart-preview-img {
  height: 200px;
}

.single-cart-item-container {
  border-bottom: 2px solid gainsboro;
}

.total-box {
  border: 2px solid black;
  width: fit-content;
  padding: 6px;
  border-radius: 4px;
}

.total-text {
  font-size: 25px;
  border-bottom: 2px solid black;
  width: fit-content;
}

.cart-summary-container {
  background-color: antiquewhite;
}

.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 119, 0);
  height: 1000px;
  background-color: rgb(67, 96, 210);
}

.please-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: bisque;
  padding: 0;
  margin: 0;
  height: 100px;
}

.please-text {
  font-family: fantasy;
}
