.create-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-product-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(233, 233, 233);
  width: 500px;
  border-radius: 12px;
}

.form-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  margin: 5px;
}

.form-item > input {
  width: 300px;
  border-radius: 3px;
}

.form-button {
  height: 40px;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
  border: 2px solid black;
  background-color: white;
  margin: 10px;
}

.form-button:hover {
  cursor: pointer;
  background-color: rgb(224, 224, 224);
}

textarea {
  max-width: fit-content;
  max-height: fit-content;
}
